/* Collezione di tutti gli url richiamabili tramite le api */
/* Lì dove nell'url sono necessari dei parametri questi sono stati riportati
   con la "@" come segnaposto e devono essere sostituiti a runtime con il replace */

const apiUrl = {

    // Api per l'autenticazione
    authapi : {
        // Permette di tornare il token di autenticazione ed il refresh token
        // da utilizzare per riottenere un nuovo access token
        get_token : '/api/token/',

        // Utilizzanto il refresh token ottenuto dalla vista /api/token/
        // permette di ottenere un nuovo access token senza rihciedere nuovamente
        // le credenziali utente
        refresh_token : '/api/token/refresh/',

        // Torna le informazioni riguardanti l'utente loggato.
        get_user_info : '/api/auth/get_user_info/',

        // Permette di settare la mail dell'utente e quindi ricevere le notifiche
        manage_user_email : '/api/auth/manage_user_email/'
    },

    // Api per la gestione delle iscrizioni alle gare
    iscrizioni : {
        // get_lista_gare_by_user : GET - torna la lista delle gare cui l'utente è iscritto o meno
        get_lista_gare_by_user : '/api/iscrizioni/get_lista_gare_by_user/',

        //get_iscr_archive_by_user : GET - torna la lista delle gare archiviate cui l'utente si
        //e' isritto in passato.
        get_iscr_archive_by_user : '/api/iscrizioni/get_iscr_archive_by_user/',

        //get_lista_iscr_by_gara : GET - torna la lista degli atleti iscritti ad una determinata gara
        get_lista_iscr_by_gara : '/api/iscrizioni/get_lista_iscr_by_gara/@id_gara/',

        // get_iscr_detail : GET - torna il dettaglio di un iscrizione ad una gara
        get_iscr_detail : '/api/iscrizioni/get_iscr_detail/@id_iscr/',

        // iscrizione_gara : POST - esegue l'iscrizione alla gara, si devono passare:
        // id_gara : id della gara cui iscriversi
        // info_richieste: se la gara richiede info addizionali in sede di iscrizione,
        //                 devono essere passate in questo campo
        iscrizione_gara : '/api/iscrizioni/iscrizione_gara/',

        // elimina_iscrizione : DELETE - esegue l'eliminazione di un'iscrizione partendo
        // dall'id della gara da cui disiscriversi.
        elimina_iscrizione : '/api/iscrizioni/elimina_iscrizione/@id_gara/',

        // setta_pagamento_iscrizione : POST - data una gara ed il valore del pagamento
        //                              va a settare l'iscrizione di quella gara come
        //                              pagata o meno, si deve passare:
        // pagato : boolean che dice se settare a pagato (True) oppure no (False)
        //          l'iscrizione alla gara
        setta_pagamento_iscrizione : '/api/iscrizioni/setta_pagamento_iscrizione/@id_gara/'
        
    }

}

export { apiUrl };
