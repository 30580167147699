<template>
  <div>
    <CustomOverlay :show="show_overlay" :text="'Attendere...'">
      <!-- Pulsante per tornare alla pagina delle iscrizioni -->
      <b-row class="mt-3 mr-1 ml-1">
        <b-col>
          <b-button :to="{'name':'iscrizioni'}" variant="primary">
            <b-icon icon="arrow-left-circle-fill" aria-hidden="true"></b-icon> Torna alle iscrizioni
          </b-button>
        </b-col>
      </b-row>
      <!-- Tabella con il profilo dell'atleta -->
      <b-card class="mt-3 mr-1 ml-1">
        <b-card-title class="text-center">
          <b-icon scale="1.3" class="mr-3" icon='person-fill'></b-icon>Profilo atleta
        </b-card-title>
        <b-card-body>
          <b-row class="mb-3" align-h="center">
            <b-col cols="12" md="3">
              <b-table-simple stacked class="text-center">
                <b-tbody>
                  <b-tr>
                    <b-th stacked-heading="Nome">{{$store.state.user.nome}}</b-th>
                    <b-th stacked-heading="Cognome">{{$store.state.user.cognome}}</b-th>
                    <b-th stacked-heading="Tessera Fidal">{{$store.state.user.cod_tess}}</b-th>

                    <b-th v-if="$store.state.user.data_di_nascita" stacked-heading="Data di nascita">{{$store.state.user.data_di_nascita | toitadate }}</b-th>
                    <b-th v-else stacked-heading="Data di nascita">-</b-th>

                    <b-th v-if="$store.state.user.scad_vis" stacked-heading="Scadenza certificato medico">{{$store.state.user.scad_vis | toitadate }}</b-th>
                    <b-th v-else stacked-heading="Scadenza certificato medico">-</b-th>

                    <b-th v-if="$store.state.user.email" stacked-heading="Email">{{$store.state.user.email}}</b-th>
                    <b-th v-else stacked-heading="Email">-</b-th>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
          <b-row class="mb-3" align-h="center">
            <b-col cols="12" md="3" class="text-center">
              <b-button v-if="notifiche_attive" size="sm" variant="danger" @click="disabilita_notifiche">
                <b-icon icon="x-circle-fill" aria-hidden="true"></b-icon> Disattiva Notifiche
              </b-button>
              <b-button v-else size="sm" variant="primary" @click="show_email_modal=true">
                <b-icon icon="envelope-fill" aria-hidden="true"></b-icon> Attiva Notifiche
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <!-- Modale per l'inserimento dell'email per le notifiche -->
      <b-modal no-close-on-backdrop
              no-close-on-esc
              hide-header-close
              hide-footer
              centered
              @hidden="form_modal_email=null"
              title="Attiva Notifiche"
              v-model="show_email_modal">

        <!-- Body della modale -->
        <template #default="{ cancel }">
          <b-form @submit.prevent="conferma_abilitazione()">
            <b-form-group
              id="useremail-label"
              label="Inserisci il tuo indirizzo email:"
              label-for="useremail">
              <b-form-input
                id="useremail"
                v-model="form_modal_email"
                type="email"
                placeholder="scrivi qui l'indirizzo"
                centered:="true"
                required
              ></b-form-input>
            </b-form-group>
            <div class="text-right">
              <b-button class="mr-2" size="sm" variant="secondary" @click="cancel()">
                Annulla
              </b-button>
              <b-button size="sm" variant="primary" type="submit">Attiva</b-button>
            </div>
          </b-form>
        </template>
      </b-modal>
    </CustomOverlay>
  </div>
</template>


<script>
import CustomOverlay from '@/components/CustomOverlay'
import { generalLib } from '@/generalLib'
import { apiUrl } from '@/apiUrl'
import axios from 'axios'

export default {
  name: 'ProfiloAtleta',
  data () {
    return {
      // Variabile per visualizzare l'overlay di caricamento
      show_overlay : false,

      // Modale inserimento mail
      show_email_modal : false,

      // La mail inserita nel form della modale
      form_modal_email : null
    }
  },
  computed : {
    /**
     * Una piccola computed che sulla base dell'esistenza della mail ci dice
     * se le notifiche sono attive o meno
     */
    notifiche_attive : function () {
      return this.$store.state.user.email == null ? false : true;
    }
  },
  methods : {
    /**
     * Richiede conferma all'utente prima pdi procedere all'abilitazione
     * vera e propria delle notifiche.
     */
    conferma_abilitazione : function () {
      // Chiede ulteriore conferma dell'attivazione delle notifiche,
      // in caso positivo continua altrimenti chiude la modale
      let message = "Questo abiliterà la ricezione delle notifiche email generate dall'applicazione, " +
                    "potrai disattivare questa funzione in qualsiasi momento, sei sicuro di continuare ?"
      this.$bvModal.msgBoxConfirm( message, {
          title: 'Conferma operazione.',
          size:'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'Continua',
          cancelTitle: 'Annulla',
          noCloseOnEsc: true,
          hideHeaderClose: true,
          centered: true
      })
      .then( ( value ) => {
        // Sia che l'utente accetti o meno di proseguire intanto chiude
        // la modale di inserimento.
        this.show_email_modal = false;
        if ( !value ) {
          // Se l'utente non va avanti annulla il contenuto del form
          this.form_modal_email = null;
        } else {
          // Se l'utente invece vuole andare avanti chiama la funzione di abilitazione
          this.show_email_modal = false;
          this.abilita_notifiche();
        }
      });
    },
    /**
     * Esegue l'abilitazione delle notifiche inviando al backend la mail
     * che l'utente ha immesso.
     */
    abilita_notifiche : function () {

      this.show_overlay = true;

      axios.post( apiUrl.authapi.manage_user_email, { email : this.form_modal_email } )
      .then( () => {

        // Registrazione email on! Chiudiamo l'overlay, resettiamo il contenuto
        // della modale e la chiudiamo.
        this.form_modal_email = null;
        this.show_overlay = false;

        let message = "Notifiche abilitate con successo! Ti è stato inviato un messaggio " +
                      "all'indirizzo inserito per verificare la correttezza della mail " +
                      "inserita, consulta la tua casella di posta!"

        this.$bvModal.msgBoxOk( message, {
          title : 'Operazione completata',
          centered: true
        })
        .then( () => {
          // Andiamo ad aggiornare le info dell'altleta nello store
          // di modo che compaia ora anche la mail e il pulsante di
          // Attivazione/Disattivazione di modifichi di conseguenza.
          this.aggiorna_info_atleta();
        });
      })
      .catch( ( error ) => {
        this.show_overlay = false;
        generalLib.backend_error_message_box( this, error.response.data );
      });
    },
    /**
     * Disabilita le notifiche per l'utente.
     */
    disabilita_notifiche : function() {

      let message = "Questo disabiliterà la ricezione delle notifiche email generate dall'applicazione, "+
                    "potrai sempre riattivarle in un secondo momento, sei sicuro di continuare ?"
      this.$bvModal.msgBoxConfirm(message, {
          title: 'Conferma operazione.',
          size:'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'Continua',
          cancelTitle: 'Annulla',
          noCloseOnEsc: true,
          hideHeaderClose: true,
          centered: true
      })
      .then( ( value ) => {

        // Se l'utente annulla l'operazione ci fermiamo qui,
        // altrimenti richiamiamo la funzione di delete
        if ( !value ) return;

        this.show_overlay = true;

        axios.delete( apiUrl.authapi.manage_user_email )
        .then( () => {
          this.show_overlay = false;

          this.$bvModal.msgBoxOk('Ricezione delle notifiche disabilitata con successo!', {
            title : 'Operazione completata',
            centered: true
          })
          .then( () => {
            // Andiamo ad aggiornare le info dell'altleta nello store
            // di modo che compaia ora anche la mail e il pulsante di
            // Attivazione/Disattivazione di modifichi di conseguenza.
            this.aggiorna_info_atleta();
          });
        })
        .catch( ( error ) => {
          this.show_overlay = false;
          generalLib.backend_error_message_box( this, error.response.data );
        });

      });
    },
    /**
     * Rinfesca le info dell'atleta nello store di modo tale
     * che appaia, o scompaia, l'indirizzo email immesso, così
     * da segnalare correttamente se le notifiche sono attive
     * o meno.
     */
    aggiorna_info_atleta : function () {
      this.show_overlay = true;

      axios.get( apiUrl.authapi.get_user_info )
      .then( (response) => {
        this.show_overlay = false;
        this.$store.commit( 'set_user_info', response.data );
      })
      .catch( () => {

        this.show_overlay = false;

        // Informiamo l'utente del problema occorso.
        let message = "Si è verificato un errore nell'aggiornamento delle informazioni " +
                      "dell'utente e lo stato delle notifiche potrebbe non essere mostrato " +
                      "correttamente. Si prega di uscire dall'applicazione e quindi rientrare " +
                      "per visualizzare lo stato corretto."
        generalLib.backend_error_message_box( this, message );
      });
    }
  },
  components: {
    CustomOverlay
  }
}
</script>

<style scoped>
</style>
