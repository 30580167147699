<template>
  <div>
    <CustomOverlay :show="show_overlay" :text="'Attendere...'">
      <!-- Pulsante per la visualizzazione della pagina dell'archivio delle gare -->
      <b-row class="mt-3 mr-1 ml-1">
        <b-col>
          <b-button :to="{'name':'archivio'}" variant="primary">
            <b-icon icon="calendar3" aria-hidden="true"></b-icon> Gare passate
          </b-button>
        </b-col>
      </b-row>
      <!-- Bcard di contenimento della tabella -->
      <b-card title="Gare disponibili" class="mt-3 mr-1 ml-1">
        <!-- Select per la visualizzazione degli elementi per pagina -->
        <b-row class="mb-2">
          <b-col cols="12" md="2">
            <!-- Elementi visualizzati per pagina -->
            <b-form-group id="group-page-size"
                label="Elementi visualizzati:"
                label-for="page-size">

              <b-form-select id="page-size"
                            v-model="page_size"
                            :options="page_size_opt">
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
              <!-- no-provider-sorting evita che per ogni azione
                di sorting o filtraggio venga chiamato il backend, ma venga fatto dalla
                tabella stessa.-->
              <b-table responsive
                      ref="iscr_table"
                      stacked="sm"
                      :per-page="page_size"
                      :current-page="page"
                      :no-provider-sorting="true"
                      :items="item_provider"
                      :fields="fields"
                      show-empty>

                <!-- Colonna con il nome della gara, lo mettiamo in bold -->
                <!-- per dargli maggiore risalto, se rpesente viene visualizzato -->
                <!-- anche il link del volantino -->
                <template #cell(nome)="data">
                  <p><b class='nome-gara'>{{data.item.nome}}</b></p>
                  <p v-if="data.item.url_volantino">
                    <b-link :href="data.item.url_volantino" target="_blank">
                      <b class='label-volantino'>Volantino/Regolamento</b>
                    </b-link>
                  </p>
                </template>

                <!-- Colonna con la data dello svolgimento, usiamo il filtro -->
                <!-- per mettere la data in italiano -->
                <template #cell(data_gara)="data">
                  {{data.item.data_gara | toitadate }}
                </template>

                <!-- Colonna con l'indirizzo ed il link a google maps -->
                <template #cell(posizione)="data">
                  {{data.item.comune}} {{data.item.indirizzo}} {{data.item.cap}} {{data.item.prov}}
                  <b-link target='_blank' :href="compose_google_link(data.item)"><b-icon icon="geo-alt-fill"></b-icon></b-link> 
                </template>

                <!-- Colonna con la data ed ora di chiusura delle iscrizioni -->
                <!-- usiamo il filtro per mettere il formato in italiano -->
                <template #cell(chiusura_iscrizioni)="data">
                  <span v-if="data.item.chiusura_iscrizioni">
                  {{data.item.chiusura_iscrizioni | toitadatetime }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </template>

                <!-- La colonna dell'iscrione la renderizziamo a seconda dello stato, -->
                <!-- se iscritto visualizziamo il pulsante Cancella Iscrizione altrimenti -->
                <!-- il pulsante Iscriviti -->
                <template #cell(iscritto)="data">
                  <p v-if="data.item.iscritto" class='text-success'><b>ISCRITTO</b></p>
                  <p v-else class='text-danger'>NON ISCRITTO</p>
                  <b-button @click="visualizza_iscritti(data.item)" pill variant="primary" size="sm" class='action-btn btn-el-iscritti'>ELENCO ISCRITTI</b-button>
                </template>

                <!-- La colonna delle azioni la renderizziamo a seconda dello stato: -->
                <!-- * se la gara è aperta e siamo iscritti visualizziamo il pulsante Cancella Iscrizione -->
                <!--   ed il pulsante per la regolarizzazione o meno della quota di partecipazione.       -->
                <!-- * se la gara è aperta e NON siamo iscritti visualizziamo il pulsante Iscrivimi SOLO  -->
                <!--   SE LA DATA DELLA GARA NON VA OLTRE QUELLA DELLA SCADENZA DEL CERTIFICATO MEDICO.   -->
                <!--   Altrimenti visualizziziamo un avviso che ricorda del certificato scaduto.          -->
                <!-- * Se la gara è chiusa visualizziamo la scritta ISCRIZIONI CHIUSE                     -->
                <template #cell(azioni)="data">

                  <div v-if="data.item.stato == 'aperta' && data.item.iscritto">
                    <b-button  @click="cancella_iscrizione(data.item)" pill variant="danger" size="sm" class='mt-1 action-btn btn-cancella'>
                      <b-icon icon="trash-fill" aria-hidden="true"></b-icon> CANCELLA
                    </b-button>

                    <!-- QUOTA DI PARTECIPAZIONE -->
                    <!-- Se mi sono iscritto visualizzo il pulsante di pagamento della quota, se la quota è da segnare come pagata -->
                    <!-- il pulsante 'quota ok' altrimenti il pulsante 'annulla quota' -->
                    <b-button v-if="!data.item.pagato" @click="modifica_pagamento_quota(data.item, true)" pill variant="success" size="sm" class='ml-1 mt-1 action-btn'>
                      <b-icon icon="credit-card" aria-hidden="true"></b-icon> QUOTA OK
                    </b-button>
                    <b-button v-else @click="modifica_pagamento_quota(data.item, false)" pill variant="danger" size="sm" class='ml-1 mt-1 action-btn btn-quota-remove'>
                      <b-icon icon="x-circle-fill" aria-hidden="true"></b-icon> ANNULLA QUOTA
                    </b-button>
                  </div>

                  <div v-else-if="data.item.stato == 'aperta' && !data.item.iscritto && certificato_ok_per_gara(data.item.data_gara)">
                    <b-button  pill @click="conferma_iscrizione(data.item)" variant="success" size="sm" class='action-btn'>
                      ISCRIVIMI
                    </b-button>
                  </div>

                  <div class="text-center text-danger" v-else-if="data.item.stato == 'aperta' && !data.item.iscritto && !certificato_ok_per_gara(data.item.data_gara)">
                    <b-icon icon="exclamation-circle-fill"></b-icon>
                    <p style="font-size: 0.9em" class="mb-0">IMPOSSIBILE ISCRIVERSI</p>
                    <p style="font-size: 0.8em">
                      Il tuo certificato medico non risulta valido alla data della competizione.
                    </p> 
                  </div>

                  <p v-else class='text-danger'><b>ISCRIZIONI CHIUSE</b></p>
                </template>

                <!-- Spinner che informa del caricamento dati attivato dalla tabella quando viene
                      chiamata la funzione che prende i dati get_table_items --->
                <template v-slot:table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle mr-2" variant="primary"></b-spinner>
                    <strong class="text-primary">Caricamento...</strong>
                  </div>
                </template>

                <!-- Slot che visualizza un messaggio quando la tabella non ha elementi, deve essere aggiunta
                      la proprietà "show-empty" alla tabella -->
                <template v-slot:empty>
                  <p class="text-center">Nessun elemento disponibile</p>
                </template>

              </b-table>

              <!-- Paginatore della tabella -->
              <b-pagination v-model="page" :per-page="page_size" :total-rows="items_tot" align="center"></b-pagination>
          </b-col>
        </b-row>
      </b-card>
    </CustomOverlay>      
    <!-- MODALE CONFERMA ISCRIZIONE ALLA GARA -->
    <b-modal
        centered
        hide-header-close
        no-close-on-backdrop
        no-close-on-esc
        v-model="iscr_modal.show"
        title="Conferma iscrizione">
      <p>{{iscr_modal.message}}</p>
      <div v-if="iscr_modal.info_richieste">
          <p>Per questa iscrizione si richiede di inserire le informazioni necessarie riportate nel campo note:</p>
          <p><i>{{iscr_modal.note}}</i></p>
          <b-form-textarea
            id="textarea"
            v-model="iscr_modal.info_richieste_text"
            placeholder="Scrivi qui le informazioni."
            rows="3"
            max-rows="6"
          ></b-form-textarea>
      </div>

      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="secondary" @click="iscrivi_utente()">Iscrivimi</b-button>
        <b-button size="sm" variant="primary" @click="cancel()">Annulla</b-button>
      </template>
    </b-modal>

    <!-- MODALE VISUALIZZAZIONE ISCRITTI AD UNA GARA -->
    <b-modal
        centered
        no-close-on-backdrop
        no-close-on-esc
        scrollable
        v-model="iscr_users.show"
        title="Atleti partecipanti">

      <template #modal-title>Atleti partecipanti {{iscr_users.nome_gara}}</template>

      <div v-if="iscr_users.users.length == 0">
        <p class="text-center"><b-icon icon="emoji-frown"></b-icon> Nessuna adesione per il momento.</p>
      </div>
      <div v-else>
        <p class="text-center"><b>Ci sono attualmente {{iscr_users.users_num}} atleti partecipanti</b></p>
        <b-table-simple responsive>
          <b-tr>
            <b-th>Atleta</b-th>
            <b-th>Data Adesione</b-th>
          </b-tr>
          <b-tr v-for="(elem, index) in iscr_users.users" :key="index" >
            <b-td>{{elem.atleta.cognome}} {{elem.atleta.nome}}</b-td>
            <b-td>{{elem.created}}</b-td>
          </b-tr>
        </b-table-simple>
      </div>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="primary" @click="cancel()">Chiudi</b-button>
      </template>
    </b-modal>
  </div>
</template>


<script>
import CustomOverlay from '@/components/CustomOverlay'
import { generalLib } from '@/generalLib'
import { apiUrl } from '@/apiUrl'
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'TabellaIscrizioni',
  data () {
    return {

      // Visualizza/nasconde l'overlay di attesa
      show_overlay: false,

      // Numero totale di elementi ( per la paginazione )
      items_tot : 0,

      // Numero di elementi per ogni pagina
      page_size : 50,

      // Numero di pagine che è possibile visualizzare
      page_size_opt: [
        { value: 10,  text: '10'  },
        { value: 25,  text: '25'  },
        { value: 50,  text: '50'  },
        { value: 100, text: '100' },
        { value: 500, text: '500' }
      ],

      // Pagina corrente della tabella
      page: 1,

      // Definizione dei campi della tabella che andiamo a visualizzare
      fields : [
        {
          key: 'nome',
          label: 'Nome',
          sortable: true,
          class: 'nomegara'
        },
        {
          key: 'data_gara',
          label: 'Data Svolgimento',
          sortable: true,
          class: 'text-center'
        },
        {
          key: 'distanza',
          label: 'Distanze',
          sortable: false,
          class: 'text-center'
        },
        // Colonna virtuale con l'indirizzo della gara
        {
          key: 'posizione',
          label: 'Posizione',
          sortable: false,
          class: 'text-center',
          tdClass : 'positionfield'
        },
        // Class `e applicato a tutta la colonna ( header compreso )
        // tdClass esclude l'header
        {
          key: 'note',
          label: 'Note',
          sortable: false,
          class: 'notefield-align',
          tdClass : 'notefield'
        },
        {
          key: 'chiusura_iscrizioni',
          label: 'Data ed ora chiusura Iscrizioni',
          sortable: false,
          class: 'text-center'
        },        
        {
          key: 'iscritto',
          label: 'Stato Iscrizione',
          sortable: true,
          class: 'text-center'
        },
        // Colonna virtuale con le azioni che possono essere fatte
        {
          key: 'azioni',
          label: 'Azioni',
          sortable: false,
          class: 'text-center'
        }
      ],
      
      // Oggetto con gli elementi per la modale di conferma iscrizione
      // saranno poi gli stessi elementi necessari anche per l'iscrizione
      iscr_modal : {
        // Visualizza o meno la modale
        show : false,
        // Messaggio da visualizzare
        message : null,
        // Dice se visualizzare o meno la text area per le info
        // la fornire.
        info_richieste : false,
        // Campo note della gara per elencare nuovamente le info da fornire
        note : null,
        // Testo delle info richieste immesso dall'utente
        info_richieste_text : "",
        // ID della gara cui iscriversi
        id_gara : null
      },

      // Oggetto per la modale con la lista degli iscritti ad una gara
      iscr_users : {
        // Visualizza o meno la modale
        show : false,
        // Il nome della gara
        nome_gara : "",
        // La lista degli utenti iscritti, ci mettiamo direttamente
        // quanto tornato dalla vista get_lista_iscr_by_gara
        users : [],
        // Numero attuale di iscritti
        users_num : 0
      }

    }
  },
  methods: {
    /**
     * Funzione che va a caricare gli item nella tabella.
     */
    item_provider : function ( ctx, callback ) {
        let params = { page : ctx.currentPage, page_size : ctx.perPage }

        axios.get( apiUrl.iscrizioni.get_lista_gare_by_user, { params : params } )
        .then( ( response ) => {

          // Gli elementi totali
          this.items_tot = response.data.count;

          // Torno i risultati
          callback( response.data.results );

        })
        .catch( ( error ) => {
          let mess = "Si è verificato un errore nel caricamento dei dati:"
          generalLib.backend_error_message_box( this, error.response.data, mess );
          callback([]);
        });

        // Must return null or undefined to signal b-table that callback is being used
        return null

    },
    /**
     * Apre la modale di conferma di un'iscrizione passandogli i dati
     * dall'oggetto che rappresenta la gara.
     */
    conferma_iscrizione : function ( data_obj ) {
      this.iscr_modal.message = 'Iscriversi alla gara "' + data_obj.nome + '" ?'
      this.iscr_modal.info_richieste = data_obj.info_richieste;
      this.iscr_modal.note = data_obj.note;
      this.iscr_modal.id_gara = data_obj.id;
      this.iscr_modal.show = true;
    },
    /**
     * Esegue l'iscrizione dell'utente ad una specifica gara utilizzando le informazioni
     * prese dalla modale. E` la funzione richiamata all' ok sulla modale di iscrizione.
     */
    iscrivi_utente : function () {
      
      // Chiudiamo la modale ed attiviamo l' overlay
      this.iscr_modal.show = false;
      this.show_overlay = true;

      let iscr_data = { id_gara : this.iscr_modal.id_gara, info_richieste : this.iscr_modal.info_richieste_text };

      axios.post( apiUrl.iscrizioni.iscrizione_gara, iscr_data )
      .then( () => {

        // Iscrizione ok! Chiudiamo l'overlay e
        // visualizziamo la modale informativa.
        this.show_overlay = false;

        this.$bvModal.msgBoxOk('Iscrizione confermata!', {
          title : 'Operazione completata',
          centered: true
        })
        .then( () => {
          // Alla chiusura della message box refresciamo la tabella
          this.$refs.iscr_table.refresh();
        });
      })
      .catch( ( error ) => {
        this.show_overlay = false;
        generalLib.backend_error_message_box( this, error.response.data );
      });
   
    },
    /**
     * Funzione che va a rimuovere un'iscrizione.
     */
    cancella_iscrizione : function ( data_obj ) {

      let message = "Sei sicuro di rimuovere l'iscrizione a: " + data_obj.nome + " ?";

      this.$bvModal.msgBoxConfirm( message, {
        title: 'Conferma azione.',
        size: 'md',
        buttonSize: 'sm',
        okTitle: 'Procedi',
        cancelTitle: 'Annulla',
        hideHeaderClose: true,
        centered: true
      })
      .then( ( answer ) => {

        if ( !answer ) return null;

        this.show_overlay = true;
        let call_url = apiUrl.iscrizioni.elimina_iscrizione.replace('@id_gara', data_obj.id );

        axios.delete( call_url )
        .then( () => {

          // Cancellazione ok! Chiudiamo l'overlay e
          // visualizziamo la modale informativa.
          this.show_overlay = false;

          this.$bvModal.msgBoxOk('Cancellazione effettuata.', {
            title : 'Operazione completata',
            centered: true
          })
          .then( () => {
            // Alla chiusura della message box refresciamo la tabella
            this.$refs.iscr_table.refresh();
          });
        })
        .catch( ( error ) => {
          this.show_overlay = false;
          generalLib.backend_error_message_box( this, error.response.data );
        });        

      });
    },
    /**
     * Visualizza gli iscritti dalla gara.
     */
    visualizza_iscritti : function ( data_obj ) {

      this.show_overlay = true;
      let call_url = apiUrl.iscrizioni.get_lista_iscr_by_gara.replace('@id_gara', data_obj.id );

      axios.get( call_url )
      .then( ( response ) => {
        
        // Settiamo l'oggetto interno con i nuovi dati per popolare la modale
        // di visualizzazione poi chiudiamo l'overlay di caricamento
        // e quindi visualissiamo la modale.
        this.iscr_users.nome_gara = data_obj.nome;

        // La data created dell'iscrizione la convertiamo con moment.js nel formato italiano
        // prima pero' svuotimo l'array
        this.iscr_users.users = [];
        for ( let elem of response.data ){
          elem.created = moment(elem.created).format('DD-MM-YYYY, HH:mm:ss');
          this.iscr_users.users.push( elem );
        }

        // Numero di iscritti
        this.iscr_users.users_num = this.iscr_users.users.length;

        this.show_overlay = false;
        this.iscr_users.show = true;

      })
      .catch( (error) => {
          this.show_overlay = false;
          generalLib.backend_error_message_box( this, error.response.data );
      });

    },
    /**
     * Modifica lo stato di pagamento della quota della gara
     *
     * - data_obj : oggetto con i dati della linea della tabella rappresetante la gara
     * - value: true o false per settare la quota com epagata o meno
     */
    modifica_pagamento_quota : function ( data_obj, value ) {

      let message = "Stai confermando il pagamento della quota per la gara \"" + data_obj.nome + "\". " +
                     "Sei sicuro di procedere?"

      if ( !value ) {
        message = "Stai annullando il pagamento della quota per la gara \"" + data_obj.nome + "\". " +
                  "Sei sicuro di procedere?"
      }

      this.$bvModal.msgBoxConfirm( message, {
        title: 'Conferma azione.',
        size: 'md',
        buttonSize: 'sm',
        okTitle: 'Procedi',
        cancelTitle: 'Annulla',
        hideHeaderClose: true,
        centered: true
      })
      .then( ( answer ) => {

        if ( !answer ) return null;

        this.show_overlay = true;
        let call_url = apiUrl.iscrizioni.setta_pagamento_iscrizione.replace('@id_gara', data_obj.id );

        axios.post( call_url, { pagato : value } )
        .then( () => {

          // Oprazione ok! Chiudiamo l'overlay e
          // visualizziamo la modale informativa.
          this.show_overlay = false;

          let operation_message = 'La quota risulta ora pagata.'

          if ( !value ) { operation_message = 'La quota risulta ora come non pagata' }

          this.$bvModal.msgBoxOk( operation_message, {
            title : 'Operazione completata',
            centered: true
          })
          .then( () => {
            // Alla chiusura della message box refresciamo la tabella
            this.$refs.iscr_table.refresh();
          });
        })
        .catch( ( error ) => {
          this.show_overlay = false;
          generalLib.backend_error_message_box( this, error.response.data );
        });

      });
    },
    /**
     * Dato l'oggetto della tabella ritorna il link per google map
     */
    compose_google_link : function ( data_obj ) {
      return generalLib.google_map_link( data_obj.comune, data_obj.cap, data_obj.prov, data_obj.indirizzo );
    },
    /**
     * Verifica se la data di una gara va oltre quella di validità del certificato medico.
     * Torna true se ok, false altrimenti.
     */
    certificato_ok_per_gara : function ( data_gara ) {
      let d_gara = moment( data_gara );
      let d_certificato = moment( this.$store.state.user.scad_vis );
      return d_gara <= d_certificato ? true : false; 
    }

  },
  components: {
    CustomOverlay
  }

}
</script>

<style scoped>

/* CAMPO NOME GARA */
/* Allineamento: in mobile allineamo al centro in desktop a sinistra */
@media (min-width: 769px) {
  .table-responsive :deep( .nomegara ) {
    text-align: left !important;
  }
}
@media (max-width: 769px) {
  .table-responsive :deep( .nomegara ) {
    text-align: center !important;
  }
}


/* CAMPO NOTE */
/* Facciamo in modo che si vedano gli A-Capo neelle note */
.table-responsive :deep( .notefield ) {
  white-space: pre-wrap;
}

/* Allineamento: in mobile allineamo al centro in desktop a sinistra */
@media (min-width: 769px) {
  .table-responsive :deep( .notefield-align ) {
    text-align: left !important;
  }
}
@media (max-width: 769px) {
  .table-responsive :deep( .notefield-align ) {
    text-align: center !important;
  }
}


/* CAMPO POSIZIONE */
/* Non facciamo espandere troppo la colonna
   su schermi desktop. In tutti i casi rendiamo il testo più piccolo */
.table-responsive :deep( .positionfield ) {
  font-size: 0.9em;
}
@media (min-width: 769px) {
  .table-responsive :deep( .positionfield ) {
    max-width: 200px;
  }
}


/* Pulsanti di azione ( elenco iscritti, cancella, iscrivimi... )
   Negli schermi grandi usiamo pulsanti più piccoli, usiamo quelli
   standard negli schermi picccoli */
@media (min-width: 769px) {
  .table-responsive :deep( .action-btn ) {
    font-size: x-small;
    font-weight: bold;
  }
}

.table-responsive :deep( .action-btn ) {
  font-weight: bold;
}

/* Colori specifici per il pulsante 'ELENCO ISCRITTI' */
.table-responsive :deep( .btn-el-iscritti ) {
  background: #16398b;
  border-color: #16398b;
}
.table-responsive :deep( .btn-el-iscritti:hover ) {
  background: #2b54b5;
  border-color: #2b54b5;
}

/* Colori specifici per il pulsante 'CANCELLA' */
.table-responsive :deep( .btn-cancella ) {
  background: #b91f1f;
  border-color: #b91f1f;
}
.table-responsive :deep( .btn-cancella:hover ) {
  background: #d03a3a;
  border-color: #d03a3a;
}

/* Colori specifici per il pulsante di rimozione del pagamento della quota */
.table-responsive :deep( .btn-quota-remove ) {
  background: #b91f1f;
  border-color: #b91f1f;
}
.table-responsive :deep( .btn-quota-remove:hover ) {
  background: #d03a3a;
  border-color: #d03a3a;
}

/* Nome della gara e link del volantino */
.table-responsive :deep( .nome-gara ) {
  color: #143790;
  font-size: 1.1em;
}
.table-responsive :deep( .label-volantino ) {
  color: #00adef;
  font-size: 0.9em;
}

</style>