import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import moment from 'moment'
import jwt_decode from "jwt-decode";
import VuexPersist from 'vuex-persist'
import { EncryptStorage } from 'encrypt-storage';
import { apiUrl } from '@/apiUrl'
//import { resolve } from 'core-js/fn/promise';

Vue.use(Vuex)

// ==== EncryptStorage ====
// Il modulo va a criptare i dati del session storage.
// Per funzionare settiamo stateManagementUse a true, il quale indica che poi
// lo andremo ad associare a VuexPersist, inoltre specifichiamo quale tipologia
// di storage usare, cioè il sessionStorage.
const encryptStorage = new EncryptStorage( 'iamthesecretkey1234567', { storageType: 'sessionStorage', stateManagementUse : true } );

// ==== VuexPersist ====
// VuexPersist sincronizza gli elemento dello state con il local
// storage del browser per mantenere la persistenza quando si fa
// il reload della pagina.
// Viene aggiunto come plugin allo store ( vedi in fondo alla
// dichiarazione delo store )
// Preso spunto da: https://www.digitalocean.com/community/tutorials/vuejs-vuex-persist-state
const vuexLocalStorage = new VuexPersist({
  key: 'tainoappdata', // La chiave dove vuex-persist andrà a porre i dati nello storage

  // Se in produzione usiamo l'stanza di encrypStorage in luogo di window.localStorage
  // o window.sessionStorage per andare a criptare i dati utente con questo modulo,
  // è poi l'attributo storageType di encryptStorage che definisce dove salvare idati,
  // nel nostro caso nel session storage.
  // Se invece siamo in sviluppo usiamo il window.sessionStorage di modo tale che i dati
  // siano in chiaro e quindi possiamo debuggare più facilmente.
  // Usiamo i sessionStorage che sono autocontenuti all'interno dello stesso tab
  // se usiamo il localStorage ciò che mettiamo nella chiave è condiviso tra tutti gli
  // elementi del browser e quindi è come se la sessione utente è la stessa per tutti i tab!
  //storage: window.localStorage,
  //storage: window.sessionStorage,
  //storage: encryptStorage,
  storage : process.env.NODE_ENV === 'development' ? window.sessionStorage : encryptStorage,

  // Funzione che passa allo storage le info dello state che vogliamo conservare
  // durante tutta la sessione di lavoro.
  reducer: ( state ) => ({ token : state.token,
                           refresh_token : state.refresh_token,
                           user : state.user,
                           is_logged : state.is_logged })

  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
})


export default new Vuex.Store({
  state: {
    user : null,
    token : null,
    token_exp : null,
    refresh_token : null,
    is_logged : false
  },
  getters: {
    /**
     * Torna la scadenza della visita medica in formato italiano
     */
    scadvis_ita_format (state) {
      return moment(state.user.scad_vis).format('DD-MM-YYYY');
    },
    /**
     * Torna i giorni alla scadenza del certificato medico rispetto ad oggi.
     * Se il certificato medico è già scaduto il numero ritornato è negativo
     * ed indica i giorni passati dalla scadenza.
     */
    day_to_scadvis (state) {
      let today = moment();
      let scadenza_vis = moment(state.user.scad_vis);
      return scadenza_vis.diff(today, 'day');
    }
  },
  mutations: {
    /**
    * Setta il token di autenticazione dell'utente
    * e l'orario di scadenza
    */
    set_auth_token ( state, token ) {
      // Decodifico le informazioni del token e poi
      // setto il token e la sua sata di scadenza
      let token_infos = jwt_decode( token );
      state.token = token;
      state.token_exp = token_infos.exp;
    },
    /**
    * Cancello il token di autenticazione e l'orario
    * di scandenza
    */
    clear_auth_token ( state ) {
      state.token = null;
      state.token_exp = null;
    },
    /**
    * Setta il token di refresh dell'utente
    */
     set_refresh_token ( state, refresh_token ) {
      state.refresh_token = refresh_token;
    },
    /**
    * Cancello il token di refresh
    */
    clear_refresh_token ( state ) {
      state.refresh_token = null;
    },
    /**
     * Setta le informazioni dell'utente
     */
    set_user_info ( state, infos ) {
      state.user = infos;
    },
    /**
     * Cancella le informazioni utente  
     */
    clear_user_infos ( state ) {
      state.user = null;
    },
    /**
    * Setta un utente come loggato.
    */
    set_user_as_logged ( state ) {
      state.is_logged = true;
    },
    /**
     * Setta l'utente come non loggato
     */
    set_user_as_not_logged ( state ) {
      state.is_logged = false;
    }

  },
  actions: {
    /**
    * Action che segue il login dell'utente con i dati presi dal form.
    * Se il login avviene positivamente setta le informazioni utente nello
    * store tramite l'apposita mutation ed in modo analogo setta anche il
    * flag che indica che l'utente è loggato.
    * Torna al chiamate una promise con i dati di risposta sia che la chiamata
    * termini correttamente o meno per poter gestire poi il da farsi.
    *
    * - user_data : oggetto con i dati utente nella forma 
    *            { cod_tess : <username>, password : <password> }
    */
    login ( context, user_data ) {

      return new Promise ( ( resolve, reject ) => {

        axios.post( apiUrl.authapi.get_token, user_data )
        .then( (response) => {

          // Alla risposta corretta per l'autenticazione setto il token
          // ed il suo orario di scadenza. Setto poi il refresh token
          // e pongo inoltre l'utente come loggato con l'apposito flag
          context.commit( 'set_auth_token', response.data.access );
          context.commit( 'set_refresh_token', response.data.refresh );
          context.commit( 'set_user_as_logged' );

          // Eseguo ora la seconda chiamata per avere e settare le informazioni
          // dell'utente loggato, se tutto ok risolviamo la promise
          axios.get( apiUrl.authapi.get_user_info )
          .then( (response) => {
            context.commit( 'set_user_info', response.data );
            resolve(null);
          })
          .catch( ( error ) => {
            // Se questa chiamata fallisce prima di fare il reject facciamo il
            // logout
            context.dispatch( 'logout' );
            reject( error );
          });
        })
        .catch( ( error ) => {
          reject( error );
        });
      });

    },
    /**
     * Esegue il log out andando a ripulire i dati dello state 
     */
    logout ( context ) {
      context.commit( 'clear_auth_token' );
      context.commit( 'clear_refresh_token');
      context.commit( 'set_user_as_not_logged' );
      context.commit( 'clear_user_infos' );
    }
  },
  modules: {
  },
  plugins: [vuexLocalStorage.plugin]
})
