<template>
  <div>
    <CustomOverlay :show="show_overlay" :text="'Attendere...'">
      <!-- Pulsante per tornare alla pagina delle iscrizioni -->
      <b-row class="mt-3 mr-1 ml-1">
        <b-col>
          <b-button :to="{'name':'iscrizioni'}" variant="primary">
            <b-icon icon="arrow-left-circle-fill" aria-hidden="true"></b-icon> Torna alle iscrizioni
          </b-button>
        </b-col>
      </b-row>
      <!-- Bcard di contenimento della tabella -->
      <b-card title="Gare passate" sub-title="Lista delle gare cui hai partecipato in passato" class="mt-3 mr-1 ml-1">
        <!-- Select per la visualizzazione degli elementi per pagina -->
        <b-row class="mb-2">
          <b-col cols="12" md="2">
            <!-- Elementi visualizzati per pagina -->
            <b-form-group id="group-page-size"
                label="Elementi visualizzati:"
                label-for="page-size">

              <b-form-select id="page-size"
                            v-model="page_size"
                            :options="page_size_opt">
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
              <!-- no-provider-sorting evita che per ogni azione
                di sorting o filtraggio venga chiamato il backend, ma venga fatto dalla
                tabella stessa.-->
              <b-table responsive
                      ref="iscr_table"
                      stacked="sm"
                      :per-page="page_size"
                      :current-page="page"
                      :no-provider-sorting="true"
                      :items="item_provider"
                      :fields="fields"
                      show-empty>

                <!-- Colonna con il nome della gara, lo mettiamo in bold -->
                <!-- per dargli maggiore risalto, se rpesente viene visualizzato -->
                <!-- anche il link del volantino -->
                <template #cell(nome)="data">
                  <p><b class='nome-gara'>{{data.item.gara.nome}}</b></p>
                  <p v-if="data.item.gara.url_volantino">
                    <b-link :href="data.item.gara.url_volantino" target="_blank">
                      <b class='label-volantino'>Volantino/Regolamento</b>
                    </b-link>
                  </p>
                </template>

                <!-- Colonna con la data dello svolgimento, usiamo il filtro -->
                <!-- per mettere la data in italiano -->
                <template #cell(data_gara)="data">
                  {{data.item.data_gara | toitadate }}
                </template>

                <!-- Colonna con l'indirizzo ed il link a google maps -->
                <template #cell(posizione)="data">
                  {{data.item.gara.comune}} {{data.item.gara.indirizzo}} {{data.item.gara.cap}} {{data.item.gara.prov}}
                  <b-link target='_blank' :href="compose_google_link(data.item)"><b-icon icon="geo-alt-fill"></b-icon></b-link> 
                </template>

                <!-- Colonna con le informazioni fornite per l'iscrizione -->
                <template #cell(info_richieste)=data>
                  <p v-if="data.item.info_richieste">
                    {{data.item.info_richieste}}
                  </p>
                  <p v-else>-</p>
                </template>


                <!-- La colonna delle azioni la renderizziamo a seconda dello stato, -->
                <!-- se iscritto visualizziamo il pulsante Cancella Iscrizione altrimenti -->
                <!-- il pulsante Iscriviti -->
                <template #cell(azioni)="data">
                  <b-button @click="visualizza_iscritti(data.item)" pill variant="primary" size="sm" class='action-btn btn-el-iscritti'>ELENCO ISCRITTI</b-button>
                </template>

                <!-- Spinner che informa del caricamento dati attivato dalla tabella quando viene
                      chiamata la funzione che prende i dati get_table_items --->
                <template v-slot:table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle mr-2" variant="primary"></b-spinner>
                    <strong class="text-primary">Caricamento...</strong>
                  </div>
                </template>

                <!-- Slot che visualizza un messaggio quando la tabella non ha elementi, deve essere aggiunta
                      la proprietà "show-empty" alla tabella -->
                <template v-slot:empty>
                  <p class="text-center">Nessun elemento disponibile</p>
                </template>

              </b-table>

              <!-- Paginatore della tabella -->
              <b-pagination v-model="page" :per-page="page_size" :total-rows="items_tot" align="center"></b-pagination>
          </b-col>
        </b-row>
      </b-card>
    </CustomOverlay>
    <!-- MODALE VISUALIZZAZIONE ISCRITTI AD UNA GARA -->
    <b-modal
        centered
        no-close-on-backdrop
        no-close-on-esc
        scrollable
        v-model="iscr_users.show"
        title="Atleti partecipanti">

      <template #modal-title>Altri atleti che hanno corso con te</template>

      <div v-if="iscr_users.users.length == 0">
        <p class="text-center"><b-icon icon="emoji-frown"></b-icon> Nessuno oltre te ha partecipato.</p>
      </div>
      <div v-else>
        <p class="text-center"><b>Hanno partecipato {{iscr_users.users_num}} atleti oltre te.</b></p>
        <b-table-simple responsive>
          <b-tr>
            <b-th>Atleta</b-th>
            <b-th>Data Adesione</b-th>
          </b-tr>
          <b-tr v-for="(elem, index) in iscr_users.users" :key="index" >
            <b-td>{{elem.atleta.cognome}} {{elem.atleta.nome}}</b-td>
            <b-td>{{elem.created}}</b-td>
          </b-tr>
        </b-table-simple>
      </div>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="primary" @click="cancel()">Chiudi</b-button>
      </template>
    </b-modal>
  </div>
</template>


<script>
import CustomOverlay from '@/components/CustomOverlay'
import { generalLib } from '@/generalLib'
import { apiUrl } from '@/apiUrl'
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'GareArchiviate',
  data () {
    return {

      // Visualizza/nasconde l'overlay di attesa
      show_overlay: false,

      // Numero totale di elementi ( per la paginazione )
      items_tot : 0,

      // Numero di elementi per ogni pagina
      page_size : 50,

      // Numero di pagine che è possibile visualizzare
      page_size_opt: [
        { value: 10,  text: '10'  },
        { value: 25,  text: '25'  },
        { value: 50,  text: '50'  },
        { value: 100, text: '100' },
        { value: 500, text: '500' }
      ],

      // Pagina corrente della tabella
      page: 1,

      // Definizione dei campi della tabella che andiamo a visualizzare
      fields : [
        {
          key: 'nome',
          label: 'Nome',
          sortable: true,
          class: 'nomegara'
        },
        {
          key: 'data_gara',
          label: 'Data Svolgimento',
          sortable: true,
          class: 'text-center'
        },
        {
          key: 'gara.distanza',
          label: 'Distanze',
          sortable: false,
          class: 'text-center'
        },
        // Colonna virtuale con l'indirizzo della gara
        {
          key: 'posizione',
          label: 'Posizione',
          sortable: false,
          class: 'text-center',
          tdClass : 'positionfield'
        },
        // Class `e applicato a tutta la colonna ( header compreso )
        // tdClass esclude l'header
        {
          key: 'gara.note',
          label: 'Note',
          sortable: false,
          class: 'notefield-align',
          tdClass : 'notefield'
        },
        {
          key: 'info_richieste',
          label: 'Informazioni fornite',
          sortable: false,
          class: 'text-center'
        },   
        // Colonna virtuale con le azioni che possono essere fatte
        {
          key: 'azioni',
          label: 'Azioni',
          sortable: false,
          class: 'text-center'
        }
      ],
      
      // Oggetto per la modale con la lista degli iscritti ad una gara
      iscr_users : {
        // Visualizza o meno la modale
        show : false,
        // Il nome della gara
        nome_gara : "",
        // La lista degli utenti iscritti, ci mettiamo direttamente
        // quanto tornato dalla vista get_lista_iscr_by_gara
        users : [],
        // Numero attuale di iscritti
        users_num : 0
      }

    }
  },
  methods: {
    /**
     * Funzione che va a caricare gli item nella tabella.
     */
    item_provider : function ( ctx, callback ) {
        let params = { page : ctx.currentPage, page_size : ctx.perPage }

        axios.get( apiUrl.iscrizioni.get_iscr_archive_by_user, { params : params } )
        .then( ( response ) => {

          // Gli elementi totali
          this.items_tot = response.data.count;

          // Torno i risultati
          callback( response.data.results );

        })
        .catch( ( error ) => {
          let mess = "Si è verificato un errore nel caricamento dei dati:"
          generalLib.backend_error_message_box( this, error.response.data, mess );
          callback([]);
        });

        // Must return null or undefined to signal b-table that callback is being used
        return null

    },
    /**
     * Visualizza gli iscritti dalla gara archiviata.
     */
    visualizza_iscritti : function ( data_obj ) {

      this.show_overlay = true;
      let call_url = apiUrl.iscrizioni.get_lista_iscr_by_gara.replace('@id_gara', data_obj.gara.id );

      axios.get( call_url )
      .then( ( response ) => {
        
        // Settiamo l'oggetto interno con i nuovi dati per popolare la modale
        // di visualizzazione poi chiudiamo l'overlay di caricamento
        // e quindi visualissiamo la modale.
        this.iscr_users.nome_gara = data_obj.gara.nome;

        // La data created dell'iscrizione la convertiamo con moment.js nel formato italiano
        // prima pero' svuotiamo l'array
        this.iscr_users.users = [];
        for ( let elem of response.data ){
          // Rimuoviamo inoltre l'atleta loggato perchè lui è quello che sta vedendo la gara e che
          // vi ha partecipato.
          if ( elem.atleta.cod_tess === this.$store.state.user.cod_tess ) continue;
          elem.created = moment(elem.created).format('DD-MM-YYYY, HH:mm:ss');
          this.iscr_users.users.push( elem );
        }

        // Numero di iscritti
        this.iscr_users.users_num = this.iscr_users.users.length;

        this.show_overlay = false;
        this.iscr_users.show = true;

      })
      .catch( (error) => {
          this.show_overlay = false;
          generalLib.backend_error_message_box( this, error.response.data );
      });

    },
    /**
     * Dato l'oggetto della tabella ritorna il link per google map
     */
    compose_google_link : function ( data_obj ) {
      return generalLib.google_map_link( data_obj.gara.comune, data_obj.gara.cap, data_obj.gara.prov, data_obj.gara.indirizzo );
    }
  },
  components: {
    CustomOverlay
  }

}
</script>

<style scoped>

/* CAMPO NOME GARA */
/* Allineamento: in mobile allineamo al centro in desktop a sinistra */
@media (min-width: 769px) {
  .table-responsive :deep( .nomegara ) {
    text-align: left !important;
  }
}
@media (max-width: 769px) {
  .table-responsive :deep( .nomegara ) {
    text-align: center !important;
  }
}


/* CAMPO NOTE */
/* Facciamo in modo che si vedano gli A-Capo nelle note */
.table-responsive :deep( .notefield ) {
  white-space: pre-wrap;
}

/* Allineamento: in mobile allineamo al centro in desktop a sinistra */
@media (min-width: 769px) {
  .table-responsive :deep( .notefield-align ) {
    text-align: left !important;
  }
}
@media (max-width: 769px) {
  .table-responsive :deep( .notefield-align ) {
    text-align: center !important;
  }
}


/* CAMPO POSIZIONE */
/* Non facciamo espandere troppo la colonna
   su schermi desktop. In tutti i casi rendiamo il testo più piccolo */
.table-responsive :deep( .positionfield ) {
  font-size: 0.9em;
}
@media (min-width: 769px) {
  .table-responsive :deep( .positionfield ) {
    max-width: 200px;
  }
}


/* Pulsanti di azione
   Negli schermi grandi usiamo pulsanti più piccoli, usiamo quelli
   standard negli schermi picccoli */
@media (min-width: 769px) {
  .table-responsive :deep( .action-btn ) {
    font-size: x-small;
    font-weight: bold;
  }
}

.table-responsive :deep( .action-btn ) {
  font-weight: bold;
}

/* Colori specifici per il pulsante 'ELENCO ISCRITTI' */
.table-responsive :deep( .btn-el-iscritti ) {
  background: #16398b;
  border-color: #16398b;
}
.table-responsive :deep( .btn-el-iscritti:hover ) {
  background: #2b54b5;
  border-color: #2b54b5;
}

/* Nome della gara e link del volantino */
.table-responsive :deep( .nome-gara ) {
  color: #143790;
  font-size: 1.1em;
}
.table-responsive :deep( .label-volantino ) {
  color: #00adef;
  font-size: 0.9em;
}

</style>