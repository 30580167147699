<template>
  <div>
    <CustomOverlay :show="show_overlay" :text="'Login in corso...'">
      <b-container class="min-vh-100">
        <b-row class="min-vh-100 justify-content-md-center align-items-center">
          <b-col lg="5">
            <!-- Logo Taino -->
            <b-img class="mb-3" center fluid :src="logo_img"></b-img>

            <!-- Sottotitolo -->
            <h3 class='text-center'>Iscrizioni Gare</h3>

            <!-- Form di login -->
            <b-form @submit.prevent="esegui_login" class="p-4 login_form">
              <!-- Inserimento codice tessera fidal -->
              <b-form-group
                id="cod-tess-group"
                label="Codice Tessera Fidal:"
                label-for="cod-tess"
                class='mb-1'
              >
                <b-input-group>
                  <b-input-group-prepend>
                    <b-icon icon="person" class='m-1' font-scale="2"></b-icon>
                  </b-input-group-prepend>
                  <b-form-input
                    id="cod-tess"
                    v-model="cod_tess"
                    type="text"
                    :state="cod_tess_state"
                    required
                    placeholder="Scrivi il codice della tua tessera fidal"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>

              <!-- Insermento password -->
              <!-- <b-form-group
                id="password-group"
                label="Password:"
                label-for="password"
                class='mb-2'
              >
                <b-input-group>
                  <b-input-group-prepend>
                    <b-icon icon="eye-slash" class='m-1' font-scale="2"></b-icon>
                  </b-input-group-prepend>
                  <b-form-input
                    id="password"
                    v-model="password"
                    type="password"
                    :state="password_state"
                    required
                    placeholder="Scrivi la password"
                  ></b-form-input>
                </b-input-group>
              </b-form-group> -->
              <b-row align-h="center">
                <b-col class="text-center">
                  <b-button id="access_button" type="submit">Accedi</b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-row>
      </b-container>
    </CustomOverlay>
  </div>
</template>


<script>
import CustomOverlay from '@/components/CustomOverlay'
import { generalLib } from '@/generalLib'
//import { apiUrl } from '@/apiUrl'
//import axios from 'axios'

export default {
  name: 'LoginForm',
  data () {
    return {
      logo_img : require('@/assets/logo_taino.png'),
      show_overlay : false,
      cod_tess : null,
      //password : null,
      cod_tess_state : null,
      //password_state : null
    }
  },
  methods : {
    /**
     * Esegue la procedura di login
     */
    esegui_login : function () {
      if ( ! this.credenziali_ok ) return;
 
      // Visualizziamo l'overlay
      this.show_overlay = true;

      // Chiamiamo la action di vuex che interroga il backend autenticando
      // l'utente e prendendo le sue informazioni.
      // Se tutto ok setta inoltre il flag logged come True.
      //let user_data = { cod_tess : this.cod_tess, password : this.password };
      let user_data = { cod_tess : this.cod_tess, password : 'WKVqpPM7dNR8Rv6V' };
      this.$store.dispatch( 'login', user_data ).then( () => {

        // Altrimeti login ok! Andiamo alla home, non togliamo l'overlay,
        // andando alla home sparirà da solo.
        this.$router.push( { name : 'home_base' } )

      })
      .catch( (error) => {
        this.show_overlay = false;
        generalLib.backend_error_message_box( this, error.response.data );
      });
    },

    /**
     * Esegue il controllo sulla presenza delle credenziali come ulteriore sicurezza
     * se il check dell'html fallisce.
     * Torna true se il check è ok, false altrimenti.
     */
    credenziali_ok : function () {
      this.cod_tess_state = this.cod_tess === null ? false : true;
      //this.password_state = this.password === null ? false : true;
      //let check_data = ( this.cod_tess_state == true &&  this.password_state == true ) ? true : false;
      //return check_data;
      return this.cod_tess_state;
    }
  },
  components: {
    CustomOverlay
  }
}
</script>

<style scope>
#access_button {
  background-color: #e31e25;
  border-color: black;
}

#access_button:hover  {
  background-color: #ff6d72;
  border-color: black;
}
.login_form {
  border: 2px solid #e31e25;
  border-radius: 5px;
}
</style>
