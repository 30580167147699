<template>
    <!-- Ridefinisce l'overlay base di bootstrap vue in maniera customizzata -->
    <b-overlay :show="show" rounded="sm" blur="1px">
      <!-- Slot proprio del bootstrap vue overlay per la customizzazione -->
      <template v-slot:overlay>
        <div class="text-center">
          <b-icon :icon="icon" font-scale="3" animation="cylon"></b-icon>
          <p>{{text}}</p>
        </div>
      </template>
      <slot>
        <!-- Qui dentro vanno gli elementi oggetto dell'overlay -->
      </slot>
    </b-overlay>
</template>


<script>
export default {
  name: 'CustomOverlay',
  data () {
    return {}
  },
  props: {
    /**
     * Visualizza o meno l'overlay, di default false
     */
    show: {
      type: Boolean,
      default : false
    },
    /**
     * Stringa da visualizzare nell'overlay sotto l'icona.
     */
    text: {
      type: String,
      default : 'Attende prego...'
    },
    /**
     * Icona da visualizzare nell'overlay
     */
    icon: {
      type: String,
      default : 'stopwatch'
    }
  }
}
</script>



<style scoped></style>
