<template>
  <b-navbar sticky class="p-2" type="dark" variant="danger">
    <b-navbar-nav class="mr-auto">
      <b-navbar-brand href="#">
        <b-img id="logo_navbar" :src="logo_img" fluid></b-img>
        <b-link id="profile-link" :to="{'name' : 'profilo-atleta'}">
          <span id="username"><b-icon class="ml-3" icon='person-fill'></b-icon> {{nome_utente}}</span>
        </b-link>
      </b-navbar-brand>
    </b-navbar-nav>
    <b-navbar-nav class="ml-auto" >
      <b-nav-item @click="logout"> Esci <b-icon icon="arrow-bar-right"></b-icon></b-nav-item>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
export default {
  name: 'Navbar',
  data () {
    return {
      logo_img : require('@/assets/logo_navbar.png')
    }
  },
  computed : {
    /**
     * Una semplice computed che da il nome utente prendendolo dallo store
     */
    nome_utente : function() {
      return this.$store.state.user.cognome + ' ' + this.$store.state.user.nome;
    }
  },
  methods : {
    logout : function () {
      const store_ref = this.$store;
      this.$router.push( { name : 'login', params: { loggingout : true } }, function () {
        // Una volta che il logout è completo possiamo cancellare le
        // info utente in vuex effettuando il logout
        store_ref.dispatch( 'logout' );
      });
    }
  }
}
</script>

<style scoped>

/* Rimpiccioliamo un pò il logo quando siamo in mobile */
#logo_navbar {
  width: 30%;
  border-radius: 5px;
}

@media (max-width: 769px) {
  #logo_navbar {
    width: 20%;
    border-radius: 5px;
  }
}

/* Il link nel nome utente non deve essere blu
   e deve essere bianco */
#profile-link {
  text-decoration : none;
  color: white;
}

/* Rimpiccioliamo il nome utente quando siamo in mobile */
@media (max-width: 769px) {
  #username {
    font-size:0.7em;
  }
}


/** Link della navbar */
.nav-item {
  color: white;
  font-weight: 900;
}
</style>