import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import moment from 'moment'
import VueCookies from 'vue-cookies'
import { apiUrl } from '@/apiUrl'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

// ############################################
// ### BOOSTRAP VUE E ICONE DI BOOSTRAP VUE ###
// ############################################
// Registriamo bootstrap vue ed il plugin per le
// icone Vue ed i cookie
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueCookies)

// ##################################################################
// ### FILTRI DI VUE PERSONALIZZATI PER IL RENDERING DEI TEMPLATE ###
// ##################################################################

// Formatta una stringa rappresentante una data nel nel formato italiano
Vue.filter('toitadate', function (value) {
  var m = moment( value );
  return m.format( 'DD-MM-YYYY' );
})

// Formatta una stringa rappresentante una data  con ora nel nel formato italiano
Vue.filter('toitadatetime', function (value) {
  var m = moment( value );
  return m.format( 'DD-MM-YYYY HH:mm:ss' );
})

// #####################################################################
// ### INTERCETTORI AXIOS SULLE CHIAMATE AL BACKEND E SULLE RISPOSTE ###
// #####################################################################
// Aggiungiamo un intercettore delle richieste nell'istanza
// globale di axios usata internamente dall'applicativo, in
// questo modo se lo user è loggato aggiungiamo alle sue
// richieste il token di autenticazione
axios.interceptors.request.use(function (config) {

  // Controllo innanzi tutto se l'utente e' loggato ed e'
  // presente un token di autenticazione
  if ( store.state.is_logged && store.state.token !== null ) {

    // Verifico se il token è scaduto
    let now = moment()
    let token_exp = moment.unix( store.state.token_exp );

    if ( token_exp.diff(now) <= 0 ) {

      // Il token è scaduto, salvo la richiesta originale che riprendo poi
      // dopo aver fatto il refresh del token, il meccanismo qui sotto è
      // ispirato a: 
      // https://stackoverflow.com/questions/49749389/axios-interceptor-request-to-refresh-id-token-when-expired-in-vuejs
      let originalRequest = config;

      // Tornerò quindi una promise che conterrà la richiesta originale la quale
      // sarà però modificata includendo il nuovo token
      return new Promise ( ( resolve, reject ) => {
        // Per fare il refresh uso una istanza di axios nuova di modo tale che l'interceptor
        // non la veda, altrimenti entrerei in un loop.
        // Eseguo quindi la post e torno la risposta.
        const axios_instance = axios.create();
        axios_instance.post( apiUrl.authapi.refresh_token, { refresh : store.state.refresh_token } )
        .then( ( response ) => {
          resolve( response );
        })
        .catch( ( error ) => {
          reject( error );
        });
      }).then((response) => {
        // In response ho la risposta della POST fatta per il refresh del token,
        // posso quindi settare il nuovo token nello store e settarlo nell'header
        // della richiesta originale che avevo in sospeso, posso quindi tornare questa
        // richiesta per poterla fare ora con il token aggiornato!
        store.commit( 'set_auth_token', response.data.access );
        originalRequest.headers['Authorization'] = 'Bearer ' + store.state.token;
        return Promise.resolve(originalRequest)
      });
  
    } else {
      // Sono loggato ed il token non è scaduto posso tornare la risposta
      // utilizzando il token in essere per autenticarmi
      config.headers['Authorization'] = 'Bearer ' + store.state.token;
      return config;
    }

  } else {
    // Se non è loggato o non ho un token torno semplicemente la risposta
    return config;
  }

    

}, function (error) {
  return Promise.reject(error);
});



// Aggiungiamo un intergettore delle risposte nell'istanza
// globale di axios per fare in modo che quando il token di
// autentcazione è scaduto venga utilizzato il token di refresh
// per rinnovare il token di accesso.
/*axios.interceptors.response.use( (response) => {
  return response
}, function (error) {
  
  const originalRequest = error.config;
  
  if (error.response.status === 403 && !originalRequest._retry) {
    originalRequest._retry = true;

    store.dispatch( 'refresh_login' ).then( () => {

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.token;
      
      return axios(originalRequest);
  
    }).catch( (error) => {
      return Promise.reject(error);
    });            
  }
  
  return Promise.reject(error);

});*/


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
