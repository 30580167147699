import store from '../store'
import Vue from 'vue'
import VueRouter from 'vue-router'

import LoginForm from '../views/LoginForm.vue'
import HomeBase from '../views/HomeBase.vue'
import TabellaIscrizioni from '../views/TabellaIscrizioni.vue'
import GareArchiviate from '../views/GareArchiviate.vue'
import ProfiloAtleta from '../views/ProfiloAtleta.vue'

//import { component } from 'vue/types/umd'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginForm,
    beforeEnter: (to, from, next) => {

      // Se non esiste il parametro loggingout ( che viene settato solo al click sul pulsante esci della navbar
      // ed indica che voglio effettuare il logout ) e risulto ancora loggato, allora non ho motivo di venire qui,
      // vengo quindi riportato alla home.
      if ( !Object.prototype.hasOwnProperty.call( to.params, 'loggingout' ) && store.state.is_logged ) {
        next( { name : 'home' } );
        return;
      }

      next();
    }
  },
  {
    path: '/',
    name: 'home_base',
    component: HomeBase,
    // La root la ridirezioniamo verso la tabella delle iscrizioni
    // che visualizziamo come prima pagina all'ingresso nella app.
    redirect : { name : 'iscrizioni'},
    beforeEnter: (to, from, next) => {
      // Se non siamo loggati torniamo alla pagina di login
      if ( store.state.is_logged ) {
        next();
        return
      }
      next( { name : 'login' } );
    },
    children : [
      {
        path: '/iscrizioni',
        name: 'iscrizioni',
        component: TabellaIscrizioni
      },
      {
        path: '/archivio',
        name: 'archivio',
        component: GareArchiviate
      },
      {
        path: '/profilo-atleta',
        name: 'profilo-atleta',
        component: ProfiloAtleta
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
