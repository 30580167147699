<template>
    <!-- ALERT CERTIFICATO -->
    <!-- Visualizza un alert che indica che il certificato medico sta per scadere quando mancano 31 giorni -->
    <!-- al termine della validità. Ricorda invece che il certificato è scaduto quando la data di validità -->
    <!-- è passata. -->
    <div>
        <!-- Alert che segnala l'avvicinarsi della scadenza del certificato medico -->
        <b-alert class='mt-3 mr-1 ml-1' :show="certificato_in_scadenza" variant="danger">
            <p class="h5">
                <b-icon font-scale="1.5" icon="exclamation-triangle"></b-icon> Il tuo certificato medico
                scadrà il <b>{{$store.getters.scadvis_ita_format}}</b>. Rinnovalo appena possibile,
                al momento <u>potrai iscriverti solo alle gare non oltre tale data</u>.
            </p>
        </b-alert>
        <!-- Alert che segnala che il certificato medifo è scaduto -->
        <b-alert class='mt-3 mr-1 ml-1' :show="certificato_scaduto" variant="danger">
            <p class="h5">
                <b-icon font-scale="1.5" icon="exclamation-triangle"></b-icon> Il tuo certificato medico
                è scaduto il <b>{{$store.getters.scadvis_ita_format}}</b>. Rinnovalo appena possibile,
                per poter continuare ad iscriverti alle prossime gare!
            </p>
        </b-alert>
    </div>
</template>

<script>
export default {
  name: 'AlertCertificato',
  data () {
    return {}
  },
  computed : {
    /**
     * Torna true quando mancamo più 31 giorni alla scadenza del certificato.
     */
    certificato_in_scadenza : function () {
      let res = false;
      if ( this.$store.getters.day_to_scadvis >= 0 && this.$store.getters.day_to_scadvis < 31 ) {
        res = true;
      }
      return res;
    },
    /**
     * Torna true se il certificato è oramai scaduto.
     */
    certificato_scaduto : function () {
      let res = false;
      if ( this.$store.getters.day_to_scadvis < 0 ) {
        res = true;
      }
      return res;
    }
  }
}
</script>

<style scoped>
</style>