<template>
    <!-- ALERT CERTIFICATO -->
    <!-- Visualizza un alert il quale indica che le notifiche email sono disattivate   -->
    <!-- permette quindi di attivarle o di nascorndere il messaggio per non essere più -->
    <!-- disturbati -->
    <b-alert class="mt-1 mr-1 ml-1" :show="show_alert" variant="light">
        <b-iconstack font-scale="1.5">
            <b-icon stacked icon="mailbox" scale="0.75"></b-icon>
            <b-icon stacked icon="slash-circle"></b-icon>
        </b-iconstack>
        Notifiche disabilitate - <b-link class="email_alert_link" :to="{'name' : 'profilo-atleta'}">Attiva ora</b-link> | <b-link class="email_alert_link" @click="set_no_more_cookie">Non mostrare più</b-link>
    </b-alert>
</template>

<script>
export default {
  name: 'AlertEmail',
  data () {
    return {
      show_alert : false
    }
  },
  methods : {
    /**
     * Setta il cookie "Non mi interessa" e chiude l'avviso.
     * Il cookie viene settato con una durata di 6 mesi.
     */
    set_no_more_cookie : function() {
      this.$cookies.set("no_more_notify_alert","true","6m");
      this.show_alert = false;
    }
  },
  watch: {
    // Alla modifica dello stato della mail nello store, se ho settato una mail
    // nascondo la notifica, se invece l'ho tolta la visualizzo, in tutti e due i casi
    // alla modifica della mail, se presente resetto il cookie.
    '$store.state.user.email': function (newval){
      if ( newval !== null ){
        this.show_alert = false;
      } else {
        this.show_alert = true;
      }
      this.$cookies.remove("no_more_notify_alert");
    }
  },
  created() {
      // L'alert deve vedersi se:
      // - le notifiche non sono attive ( email utente a null )
      // - non è presente il cookie per non vedere più questa notifica
      if ( this.$store.state.user.email === null &&
           this.$cookies.isKey("no_more_notify_alert") === false ){
        this.show_alert = true;
      }
  }
}
</script>

<style scoped>
.email_alert_link {
  text-decoration: none;
}
</style>