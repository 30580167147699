const generalLib = {

  /******************************
   *     FUNZIONI GENERICHE
   ******************************/ 
  /**
   * Impostazioni italiane per il timepicker
   */
  timepicker_it : {
    labels: {
      labelPrevDecade: 'Decade precedente',
      labelPrevYear: "Anno precedente",
      labelPrevMonth: "Mese precedente",
      labelCurrentMonth: 'Mese corrente',
      labelNextMonth: 'Prossimo mese',
      labelNextYear: 'Prossimo anno',
      labelNextDecade: 'Prossima decade',
      labelToday: "Oggi",
      labelSelected: 'Data selezionata',
      labelNoDateSelected: 'Nessuna data selezionata',
      labelCalendar: 'Calendario',
      labelNav: 'Navigazione calendario',
      labelHelp: 'Usa i tasti freccia per navigare nel calendario'
    },
    start_weekday : 1
  },

  /**
   * Arrotonda il valore passatogli alla seconda cifra decimale, utile
   * per gli importi in euro.
   */
  arrotonda_ai_decimali : function ( val ) {
    // To fixed restituisce una stringa, per questo lo riportiamo
    // in float.
    return parseFloat( val.toFixed( 2 ) );
  },

  /**
   * Data la risposta per il download di un file prova a cercarne il nome
   * nell'header 'Content-Disposition', se non lo trova torna un nome di default.
   * Di solito infatti il contenuto dell'header avrà infatti la forma:
   *        attachment;filename=20200908_151317_WYYl.csv
   *
   * - axios_response : oggetto response di axios.
   */
  get_nome_file : function ( axios_response ) {

    const default_name = "downloaded_file";
    let content_disposition = '';

    try {
      content_disposition = axios_response.headers['content-disposition']
    } catch ( error ) {
      console.warn( "[generalLib - nome_file_scaricato]: header content disposition non trovato torno il nome di default");
      return default_name;
    }

    // Il nome del file è quello del primo gruppo individuato, cioè quello
    // indicato nell'espressione regolare dentro le parentesi tonde dopo l'uguale.
    let re = new RegExp( ".*filename=(.*)" );
    let res = re.exec( content_disposition );
    if ( res !== null ) {
      return res[1];
    } else {
      console.warn( "[generalLib - nome_file_scaricato]: nome file non rilevato nell'header content-disposition torno il nome di default");
      return default_name;
    }
  },

  /**
   * Date le "coordinate" passategli restituisce il link necessario per aprire
   * il luogo in google map. L'indirizzo può non essere passato, in tal caso la
   * localizzazione sarà meno precisa e circoscritta al solo comune.
   * Anche la provincia potrebbe mancare.
   */
  google_map_link : function ( comune, cap, provincia = null, indirizzo = null ) {

    let base_url = "https://www.google.com/maps/search/@search_string";
    let search_string = '';

    if ( indirizzo != null ) search_string += indirizzo + '+';

    search_string += comune + '+' + cap;

    if ( provincia != null ) search_string += '+' + provincia ;

    return base_url.replace( '@search_string', search_string );
  },

  /*************************************************************
   *     FUNZIONI PER VERIFICARE SE L'APP E' IN MODALITA'
   *     STAND-ALONE, CIOE' SE E' INSTALLATA IN UN DISPOSITIVO
   *     MOBILE
   *************************************************************/

  /**
   * Torna true se la app è installata nel dispositivo mobile, altrimenti torna false.
   * Funzione specifica per chrome.
   * ( https://stackoverflow.com/questions/51735869/check-if-user-has-already-installed-pwa-to-homescreen-on-chrome )
   */
  isChromeStandalone : function () {
    return window.matchMedia('(display-mode: standalone)').matches;
  },

  /**
   * Torna true se la app è in modalità standalone, cioè se risulta
   * lanciata da un link nella home di un sistema apple.
   * La proprietà standalone è infatti presente solo Safari:
   * https://developer.mozilla.org/it/docs/Web/API/Navigator
   */
  isIosStandalone : function () {
    return ('standalone' in window.navigator) && (window.navigator.standalone);
  },

  /**
   * Torna true se lo useragent è un iphone, ipad o ipod
   * false altrimenti.
   */
  isIos : function () {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test( userAgent );
  },

  /************************************
   *    FUNZIONI DI VALIDAZIONE DATI
   ************************************/

  /**
   * Validazione delle cifre in denaro.
   *
   * Verifichiamo il valore con una regex la quale accetta
   * valori solo nel formato: 123.45
   * Cioè con N cifre prima del punto e solo due cifre dopo
   * di esso.
   * Non accetta la virgola come separatore.
   * Torna true se la validazione ha successo, false altrimenti.
   */
  euro_is_valid : function ( val ) {
    let regex = /^\d*(\.\d{0,2})?$/g;
    return regex.test( val ) ;
  },

  /**
   * Valida i numeri di telefono
   */
  phone_is_valid : function ( tel ) {
    return RegExp ( "^[+]?[0-9 ]{6,30}$" ).test ( tel );
  },

  /**
   * Valida il codice fiscale, torna true se ok altrimenti torna false
   */
  cod_fisc_is_valid : function( value ) {

    var caratteri = [ "0","1","2","3","4","5","6","7","8","9","a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z" ];
    var pari      = [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25 ];
    var dispari   = [ 1, 0, 5, 7, 9, 13, 15, 17, 19, 21, 1, 0, 5, 7, 9, 13, 15, 17, 19, 21, 2, 4, 18, 20, 11, 3, 6, 8, 12, 14, 16, 10, 22, 25, 24, 23 ];
    var cod = value;
    var lettere, numeri;
    var i, test, somma;
    var lettera, carattere, resto, k;

    if ( cod === null ) return false;
    if ( cod.length != 16 ) return false;

    cod = cod.toLowerCase();

    lettere = cod.substr ( 0, 6 ) + cod.substr ( 8, 1 ) + cod.substr ( 11, 1) + cod.substr ( 15 );
    numeri  = cod.substr ( 6, 2 ) + cod.substr ( 9, 2 ) + cod.substr ( 12, 3 );

    for  ( i = 0; i < 10; i++ )
        if ( lettere.charCodeAt ( i ) < 97 || lettere.charCodeAt ( i ) > 122 ) return false;


    for  ( i = 0; i < 8; i++ )
        if ( numeri.charCodeAt ( i ) < 48 || numeri.charCodeAt ( i ) > 57 ) return false;

    //checksum del codice fiscale
    test = cod.substr ( 15,1 );
    somma = 0;
    for ( i = 0; i < 16; i = i + 2 )
    { //dispari
            carattere = cod.substr ( i, 1 );
            for ( k = 0; k < 36; k ++ )
        {
                    if ( carattere == caratteri [ k ] )
            {
                        somma += dispari [ k ];
                        break;
                }
            }
        }

        for ( i = 1; i < 15; i= i + 2 )
    { //pari
            carattere = cod.substr ( i, 1 );
            for ( k = 0; k < 36; k ++ )
        {
                    if ( carattere == caratteri [ k ] )
            {
                        somma += pari [ k ];
                        break;
                }
            }
        }

    resto = somma % 26;
    lettera = String.fromCharCode ( 97 + resto );

    if ( test != lettera )
    {
        lettera = String.fromCharCode ( 98 + resto );
        if ( test != lettera ) return false;
    }

    return true;
  },

  /*****************************************
   *     GENERAZIONE MESSAGE-BOX UTENTE
   *****************************************/

  /**
   * Visualizza a schermo una message box che elenca gli errori ricevuti
   * dalle chiamate di backend.
   *
   * - vue_component : il riferimento al componente che invoca la message box ( this )
   *                   necessario per poterla creare.
   * - error_data : l'oggetto data che torna dalla chiamata al backend ( error.response.data ) od un messaggio come stringa
   * - message : messaggio da scrivere prima della lista degli errori, da modificare opzionalmente
   */
  backend_error_message_box : function ( vue_component, error_data, message = 'Si è verificato un errore, il sistema riporta:' ) {

    // Se passiamo l'oggetto error.response.data:
    // gli errori da django rest arrivano come un oggetto dove le chiavi sono
    // le tipologie di errori, ed i valori, sono array con le descrizioni.
    // Es: {"non_field_errors":["Utente o password non corrette."]}
    // Andiamo a scorrere tutte le chiavi e a costruire una stringa di elementi <li>
    // che andiamo poi a porre come lista nella messagebox che visualizziamo a schermo.
    let error_list_nodes = [];

    // Defininiamo in "h" un alias per la createElement di vue la quale ci permette
    // di generare i nodi html da porre nella message box.
    const h = vue_component.$createElement;

    // Valuatiamo prima il caso in cui error_data non è un oggetto ma sia
    // una stringa semplice od altro.
    if ( typeof( error_data ) !== 'object' ) {
      error_list_nodes.push( h( 'li', {}, error_data ) );
    } else {
      for ( let key in error_data ) {
        // Se l'elemento associato alla chiave è un'array lo passiamo in rassega altrimenti
        // se contiene lui stesso il messaggio lo aggiungiamo
        if ( Array.isArray( error_data[ key ] ) ) {
          for ( let msg of error_data[ key ] ){
            error_list_nodes.push( h( 'li', {}, msg ) );
          }
        } else {
          error_list_nodes.push( h( 'li', {}, error_data[ key ] ) );
        }
      }
    }

    // Costruiamo la struttura finale dell'html della messagebox e la
    // visualizziamo.
    let messageVNode = h ( 'div', {}, [
                              h ( 'p', {}, message ),
                              h ( 'ul', {}, error_list_nodes )
                          ] );

    vue_component.$bvModal.msgBoxOk( [messageVNode], {
      title: "Errore.",
      buttonSize: 'sm',
      centered: true
    });

  }
}

export { generalLib };
