import { render, staticRenderFns } from "./CustomOverlay.vue?vue&type=template&id=4fae12c7&scoped=true&"
import script from "./CustomOverlay.vue?vue&type=script&lang=js&"
export * from "./CustomOverlay.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fae12c7",
  null
  
)

export default component.exports