<template>
  <b-container id="main-container" fluid>
    <!-- Navbar -->
    <Navbar/>
    <AlertEmail/>
    <!-- Messaggio di alert relativo alla scadenza del certificato medico -->
    <AlertCertificato/>
    <!-- Qui mostriamo le viste figlie della home base -->
    <router-view/>
  </b-container>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import AlertCertificato from '@/components/AlertCertificato.vue';
import AlertEmail from '@/components/AlertEmail.vue';

export default {
  name: 'Home',
  components: {
    Navbar,
    AlertCertificato,
    AlertEmail
  }
}
</script>

<style scoped>
#main-container {
  padding-right: 0px;
  padding-left: 0px;
  overflow-x: hidden;
}

</style>